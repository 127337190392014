import { ComboBox, ComboBoxChangeEvent } from '@progress/kendo-react-dropdowns'
import { ChangeEvent, CSSProperties } from 'react'
import { Card, Col, Form, Row } from 'react-bootstrap'
import { TagKeyValue } from 'types/ScheduleEvent'
import IconButtonDelete from 'views/Common/Buttons/IconButtonDelete'
import IconButtonAdd from '../Buttons/IconButtonAdd'
import FormLabelCustom from './FormLabelCustom'
import classes from './TagsTable.module.css'

const TagsTable = (props: {
    readonly?: boolean
    tags: TagKeyValue[]
    allTagNames: string[]
    showValidationErrors?: boolean
    updateTags: (tags: TagKeyValue[]) => void
}) => {
    const invalidCellStyle: CSSProperties = { border: '1px solid red' }
    const otherTagHasSameName = (name: string) => {
        return props.tags.filter((x) => x.name === name).length > 1
    }

    const tagRows = props.tags.map((tag) => {
        const isInvalid = props.showValidationErrors && (tag.name === '' || otherTagHasSameName(tag.name))

        const nameCellStyling = isInvalid ? invalidCellStyle : {}
        return (
            <Row key={tag.name} className="mb-2">
                <Col xs={6} style={nameCellStyling}>
                    <ComboBox
                        required
                        disabled={props.readonly}
                        style={{ width: '100%' }}
                        value={tag.name}
                        data={props.allTagNames}
                        allowCustom
                        placeholder="Provide a unique tag name"
                        onChange={(e: ComboBoxChangeEvent) => {
                            // remove multiple consecutive spaces, and trim
                            const tagNameCleaned = e.value?.replace(/\s\s+/g, ' ').trim()
                            const updatedTags = props.tags.map((x) => {
                                if (x.name === tag.name) {
                                    return { ...x, name: tagNameCleaned || '' }
                                }
                                return x
                            })
                            props.updateTags(updatedTags)
                        }}
                    />
                    {isInvalid && <small style={{ color: 'red' }}>Please provide a unique Tag Name</small>}
                </Col>
                <Col xs={5}>
                    <Form.Control
                        required
                        disabled={props.readonly}
                        id={`txtTag_${tag.name.toString()}_value`}
                        name={`tag_${tag.name.toString()}_value`}
                        type="text"
                        placeholder="Provide a tag value"
                        value={tag.value}
                        onChange={(e: ChangeEvent<HTMLInputElement>) => {
                            const updatedTags = props.tags.map((x) => {
                                if (x.name === tag.name) {
                                    return { ...x, value: e.target.value }
                                }
                                return x
                            })
                            props.updateTags(updatedTags)
                        }}
                    />
                </Col>
                <Col xs={1} className="pt-1">
                    {!props.readonly && (
                        <IconButtonDelete
                            onClick={() => {
                                const updatedTags = props.tags.filter((x) => x.name !== tag.name)
                                props.updateTags(updatedTags)
                            }}
                        />
                    )}
                </Col>
            </Row>
        )
    })

    if (tagRows.length === 0) {
        return null
    }
    return (
        <>
            <FormLabelCustom>Tags</FormLabelCustom>
            {!props.readonly && (
                <IconButtonAdd
                    style={{ marginLeft: 5 }}
                    onClick={() => {
                        props.updateTags([...props.tags, { name: '', value: '', index: 0 }])
                    }}
                />
            )}
            <Card>
                <Card.Body className={classes.cardBody}>
                    <Row>
                        <Col xs={6}>
                            <small>Tag Name</small>
                        </Col>

                        <Col xs={5}>
                            <small>Tag Value</small>
                        </Col>
                    </Row>
                    {tagRows}
                </Card.Body>
            </Card>
        </>
    )
}

export default TagsTable
