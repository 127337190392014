import useDetailPageParams from 'hooks/useDetailPageParams'
import { FormEvent, useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { toast } from 'react-toastify'
import globals from 'services/global/globals'
import { fetchAndSetTemplateFromId, saveScenarioAndUpdatedTemplate } from 'store/actions/templateActions'
import { profileActions } from 'store/profileStore'
import { useAppDispatch, useAppSelector } from 'store/store'
import { Scenario } from 'types/Scenario'
import { TagKeyValue } from 'types/ScheduleEvent'
import { FormControlChangeEvent, updateObjectWithFormEvent } from 'views/Common/Form/FormControls/FormControlBase'
import FormFooter from 'views/Common/Form/FormFooter'
import FormPage from 'views/Common/Form/FormPage'
import { InputTextAreaFormRow, InputTextFormRow } from 'views/Common/Form/FormRows/CustomFormRows'
import FormSection from 'views/Common/Form/FormSection'
import DialogResultEnum from 'views/Common/GenericDialogs/dialogResult'
import TagsTable from 'views/Common/Widget/TagsTable'
import ProfilesSelectorPanel from 'views/Settings/Profiles/ProfilesSelectorPanel'
import TemplateExpansionPanel from 'views/Settings/Templates/TemplateExpansionPanel'
import ScenarioSaveConfirmationDialog from './ScenarioSaveConfirmationDialog'

const ScenarioDetailPage = () => {
    const api = globals.getApi().getScenarioApi()
    const { id, operationMode } = useDetailPageParams()
    const dispatch = useAppDispatch()
    const [showConfirm, setShowConfirm] = useState<boolean>(false)
    const [validatedForm, setValidatedForm] = useState(false)
    const navigate = useNavigate()
    const [scenario, setScenario] = useState<Scenario>()
    const [scenarioModified, setScenarioModified] = useState(false)

    const templateChanged = useAppSelector((rs) => rs.profile.templateModified)

    const areTagsInValid = () => {
        const scenarioTags = scenario?.scenarioTags || []
        const anyNoNameTags = scenarioTags.filter((x) => x.name === '').length > 0
        const hasDuplicates = scenarioTags.length !== new Set(scenarioTags.map((x) => x.name)).size
        return anyNoNameTags || hasDuplicates
    }

    // Set the scenario state based on the operation mode
    useEffect(() => {
        const init = async () => {
            dispatch(profileActions.setScenarioMode(true))
            try {
                if (operationMode === 'Edit') {
                    const res = await api.getScenario(Number(id))
                    setScenario(res)
                    dispatch(fetchAndSetTemplateFromId(res.templateId))
                } else if (operationMode === 'Copy') {
                    const res = await api.copyScenario(id)
                    setScenario(res)
                    dispatch(fetchAndSetTemplateFromId(res.templateId))
                    setScenarioModified(true) // copying a scenario results in it being 'modified'
                } else {
                    const res = await api.getDefaultScenario()
                    setScenario(res)
                    dispatch(fetchAndSetTemplateFromId(res.templateId))
                }
            } catch (error: any) {
                toast.error(error.message)
            }
        }
        init()
    }, [api, dispatch, id, operationMode])

    const submitHandler = async (event: FormEvent<HTMLFormElement>) => {
        if (!scenario) {
            throw Error('Scenario is not defined')
        }
        // prevent usual form submission
        event.preventDefault()
        event.stopPropagation()

        const form = event.target as HTMLFormElement
        if (form.checkValidity() === false) {
            setValidatedForm(true)
            return
        }

        if (areTagsInValid()) {
            setValidatedForm(true)
            return
        }

        if (operationMode === 'Create' || operationMode === 'Copy') {
            try {
                await dispatch(saveScenarioAndUpdatedTemplate(scenario, 'new'))
                navigate(`/scenario/${scenario.id}/schedules`)
            } catch (error: any) {
                toast.error(error.message)
                return
            }
            toast.success('Scenario has been Created.')
        } else {
            setShowConfirm(true)
        }
    }

    const handleModifyConfirmResult = async (dialogResult: DialogResultEnum) => {
        if (!scenario) {
            return
        }
        if (dialogResult === DialogResultEnum.Completed) {
            try {
                await dispatch(saveScenarioAndUpdatedTemplate(scenario, 'save'))
                navigate(`/scenario/${scenario.id}/schedules`)
            } catch (error: any) {
                toast.error(error.message)
                return
            }
            toast.success('Scenario has been Updated.')
        }
        setShowConfirm(false)
    }

    const title = `${operationMode} Scenario`
    const footer = (
        <FormFooter
            disabledSave={!scenarioModified && !templateChanged}
            onCancel={() => {
                if (operationMode === 'Edit') {
                    navigate(`/scenario/${scenario?.id}/schedules`)
                    return
                }

                navigate('/scenarios')
            }}
        />
    )

    if (!scenario) {
        return <></>
    }

    const updateScenario = (e: FormControlChangeEvent) => {
        setScenario(updateObjectWithFormEvent(scenario, e))
        setScenarioModified(true)
    }

    return (
        <>
            <FormPage
                headingContent={title}
                footerContent={footer}
                validatedForm={validatedForm}
                onSubmit={submitHandler}
            >
                <FormSection title="Details">
                    <InputTextFormRow
                        labelText="Name"
                        fieldId="name"
                        subText="A unique name for this scenario"
                        value={scenario.name}
                        onChange={updateScenario}
                        required
                        invalidText="Enter a unique name"
                    />
                    <InputTextAreaFormRow
                        labelText="Description"
                        fieldId="description"
                        subText="A description for this scenario"
                        value={scenario.description}
                        onChange={updateScenario}
                    />
                    <TagsTable
                        tags={scenario.scenarioTags}
                        allTagNames={scenario.scenarioTags.map((x) => x.name)}
                        updateTags={(tags: TagKeyValue[]) => {
                            setScenarioModified(true)
                            setScenario((previousScenario: Scenario | undefined) => {
                                if (!previousScenario) return previousScenario
                                return {
                                    ...previousScenario,
                                    scenarioTags: tags,
                                }
                            })
                        }}
                    />
                </FormSection>
                <FormSection title="Template" padding={0}>
                    <TemplateExpansionPanel onChange={updateScenario}>
                        <ProfilesSelectorPanel />
                    </TemplateExpansionPanel>
                </FormSection>
                <FormSection title="Data Source Details">Placeholder</FormSection>
            </FormPage>
            {showConfirm && <ScenarioSaveConfirmationDialog closeCallback={handleModifyConfirmResult} />}
        </>
    )
}

export default ScenarioDetailPage
